import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Avatar,
  Image,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { FiBell, FiSettings, FiLogOut } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import bigLogo from '../assets/logo/datasphere-logo-big.svg';

const Topbar = () => {
  const location = useLocation();
  const auth = useAuth();
  const [showNotifications, setShowNotifications] = useState(false);

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/home':
        return 'Dashboard';
      case '/datasets':
        return 'Datasets';
      case '/file-preview':
        return 'File Preview';
      case '/settings':
        return 'Settings';
      default:
        return 'Ikure Data Portal';
    }
  };
  

  const firstName = auth?.user?.profile?.given_name || 'User';
  const email = auth?.user?.profile?.email || 'No Email Found';

  return (
    <Box
      as="header"
      width="calc(100% - 70px)"
      height="80px"
      bg="white"
      padding="0 20px"
      position="fixed"
      top="0"
      left="70px"
      zIndex="1001"
      display="flex"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      {/* Page Title and Breadcrumb */}
      <Flex direction="column" justify="center" ml="10px">
        <Text fontSize="lg" fontWeight="bold" color="gray.800">
          {getPageTitle(location.pathname)}
        </Text>
        <Breadcrumb fontSize="sm" color="gray.500" spacing="8px" separator=">">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href={location.pathname}>{getPageTitle(location.pathname)}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      {/* Centered Logo and "by iKure" Text */}
      <Flex flex="1" justify="center" alignItems="center">
        <Text fontSize="xl" color="gray.600" mr="4" fontWeight="bold"> {/* Increased font size */}
          iKure Data Portal |
        </Text>
        <Image
          src={bigLogo}
          alt="Datasphere Logo"
          boxSize={{ base: '120px', md: '150px', lg: '170px' }}
          objectFit="contain"
        />
      </Flex>

      {/* Right-side Icons */}
      <Flex align="center" gap="4" mr="10px">
        {/* Notification Icon with Popover */}
        <Popover
          isOpen={showNotifications}
          onClose={() => setShowNotifications(false)}
        >
          <PopoverTrigger>
            <IconButton
              aria-label="Notifications"
              icon={<FiBell />}
              variant="ghost"
              fontSize="20px"
              onClick={() => setShowNotifications(!showNotifications)}
            />
          </PopoverTrigger>
          <PopoverContent boxShadow="lg" borderRadius="md" maxWidth="250px">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody textAlign="center" py="4">
              <Text>No notifications available.</Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>

        {/* Disabled Settings Icon */}
        <IconButton
          aria-label="Settings"
          icon={<FiSettings />}
          variant="ghost"
          fontSize="20px"
          isDisabled
        />

        {/* Avatar with Menu */}
        <Menu>
          <MenuButton>
            <Avatar size="sm" name={firstName} cursor="pointer" />
          </MenuButton>
          <MenuList boxShadow="lg" borderRadius="md" p="4" minWidth="200px">
            <VStack align="stretch" spacing="3">
              <Box textAlign="center">
                <Text fontWeight="bold" fontSize="md">
                  {firstName}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {email}
                </Text>
              </Box>
              <Divider />
              <MenuItem icon={<FiLogOut />} onClick={() => auth.signoutRedirect()}>
                Logout
              </MenuItem>
            </VStack>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export default Topbar;
