import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Datasets from './pages/Datasets';
import FilePreview from './pages/FilePreview'; // Import the FilePreview page

function App() {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.activeNavigator) {
      auth.signinRedirect(); // Redirect to Keycloak for login
    }
  }, [auth]);

  if (auth.isLoading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (auth.error) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Text fontSize="lg" color="red.500">
          Oops... {auth.error.message}
        </Text>
      </Flex>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            auth.isAuthenticated ? (
              <Layout>
                <Dashboard />
              </Layout>
            ) : (
              <div>Redirecting to login...</div>
            )
          }
        />
        <Route
          path="/datasets"
          element={
            auth.isAuthenticated ? (
              <Layout>
                <Datasets />
              </Layout>
            ) : (
              <div>Redirecting to login...</div>
            )
          }
        />
        <Route
          path="/file-preview"
          element={
            auth.isAuthenticated ? (
              <Layout>
                <FilePreview />
              </Layout>
            ) : (
              <div>Redirecting to login...</div>
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
