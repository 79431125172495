import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  useToast,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FiArrowLeft, FiPlus, FiChevronDown, FiChevronRight, FiDownload, FiFileText, FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';

const Datasets = () => {
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDatasets = async () => {
      try {
        const response = await axiosInstance.get('/api/datasets');
        setDatasets(response.data);
      } catch (err) {
        setError('Failed to load datasets.');
        toast({
          title: 'Error',
          description: 'Failed to load datasets.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDatasets();
  }, [toast]);

  const handleRequestDataset = () => {
    toast({
      title: 'You are not authorized.',
      description: "You don't have the necessary permissions to request a new dataset.",
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const getFileIcon = (fileType) => {
    return <FiFileText color="gray.500" />;
  };

  const formatFileSize = (size) => {
    return `${(size / 1024).toFixed(2)} KB`;
  };

  const handleFileDownload = (url) => {
    toast({
      title: 'Download started.',
      description: 'Your file is being downloaded.',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });

    setTimeout(() => {
      window.open(url, '_blank');
    }, 1000);
  };

  const handleViewFile = (fileUrl, fileName) => {
    navigate('/file-preview', { state: { fileUrl, fileName } });
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Heading size="md" color="red.500">
          {error}
        </Heading>
      </Flex>
    );
  }

  return (
    <Box p="6" bg="white" borderRadius="md" boxShadow="sm" minHeight="80vh">
      <Button
        leftIcon={<FiArrowLeft />}
        colorScheme="gray"
        variant="outline"
        size="sm"
        mb="4"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>

      <Flex alignItems="center" mb="6">
        <Heading size="lg" color="#0765FF">Datasets</Heading>
        <Spacer />
        <Button
          leftIcon={<FiPlus />}
          colorScheme="teal"
          variant="outline"
          size="sm"
          onClick={handleRequestDataset}
        >
          Request New Dataset
        </Button>
      </Flex>

      <Box p="6" bg="#FDFDFD" borderRadius="md" boxShadow="sm" border="1px solid #E2E8F0">
        <TableContainer>
          <Table variant="simple" size="md">
            <Thead>
              <Tr bg="gray.100">
                <Th color="#0765FF" fontSize="sm" fontWeight="medium">Sl. No</Th>
                <Th color="#0765FF" fontSize="sm" fontWeight="medium">Name</Th>
                <Th color="#0765FF" fontSize="sm" fontWeight="medium">Total Files</Th>
                <Th color="#0765FF" fontSize="sm" fontWeight="medium">Status</Th>
                <Th color="#0765FF" fontSize="sm" fontWeight="medium">Last Updated</Th>
                <Th color="#0765FF" fontSize="sm" fontWeight="medium">Files</Th>
              </Tr>
            </Thead>
            <Tbody>
              {datasets.map((dataset, index) => (
                <React.Fragment key={dataset._id}>
                  <Tr _hover={{ bg: 'gray.50' }}>
                    <Td fontSize="md">{index + 1}</Td>
                    <Td fontSize="md">{dataset.name}</Td>
                    <Td fontSize="md">{dataset.totalFiles}</Td>
                    <Td fontSize="md" fontWeight="bold" color={dataset.status === 'Active' ? 'green.500' : 'red.500'}>
                      {dataset.status}
                    </Td>
                    <Td fontSize="md">{new Date(dataset.lastUpdated).toLocaleDateString()}</Td>
                    <Td>
                      <Button
                        variant="outline"
                        size="sm"
                        cursor="pointer"
                        color="#0765FF"
                        onClick={() => setExpandedRow(expandedRow === index ? null : index)}
                      >
                        {expandedRow === index ? <FiChevronDown /> : <FiChevronRight />} Expand
                      </Button>
                    </Td>
                  </Tr>
                  {expandedRow === index && (
                    <Tr>
                      <Td colSpan="6" bg="#F9FAFB">
                        <TableContainer>
                          <Table variant="simple" size="sm">
                            <Thead>
                              <Tr bg="gray.50">
                                <Th>File Name</Th>
                                <Th>Size</Th>
                                <Th>Type</Th>
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {dataset.files.map((file) => (
                                <Tr key={file._id}>
                                  <Td>
                                    <Flex alignItems="center">
                                      {getFileIcon(file.fileType)}
                                      <Text ml="2">{file.url.split('/').pop()}</Text>
                                    </Flex>
                                  </Td>
                                  <Td>{formatFileSize(file.size)}</Td>
                                  <Td>{file.fileType.toUpperCase()}</Td>
                                  <Td>
                                    <Flex gap="2">
                                      <Button
                                        size="sm"
                                        variant="outline"
                                        colorScheme="blue"
                                        leftIcon={<FiEye />}
                                        onClick={() => handleViewFile(file.url, file.url.split('/').pop())}
                                      >
                                        View
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="outline"
                                        colorScheme="blue"
                                        leftIcon={<FiDownload />}
                                        onClick={() => handleFileDownload(file.url)}
                                      >
                                        Download
                                      </Button>
                                    </Flex>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Datasets;
