import React, { useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Flex,
  Icon,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Spinner,
  useToast
} from '@chakra-ui/react';
import { FiDatabase, FiUser, FiFile, FiDownload, FiPlus, FiAlertTriangle, FiExternalLink } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';

const Dashboard = () => {
  const [insights, setInsights] = useState({
    totalUsers: 0,
    totalDatasets: 0,
    totalFiles: 0,
    totalDownloads: 0
  });
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const insightsResponse = await axiosInstance.get('/api/insights');
        setInsights(insightsResponse.data);

        const datasetsResponse = await axiosInstance.get('/api/datasets');
        setDatasets(datasetsResponse.data);
      } catch (err) {
        setError('Failed to load dashboard data.');
        toast({
          title: 'Error',
          description: 'Failed to load dashboard data.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
          icon: <FiAlertTriangle />,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [toast]);

  const totalStorageUsed = datasets.reduce((total, dataset) => total + dataset.totalSize, 0) / (1024 * 1024 * 1024); // Convert to GB

  if (loading) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="80vh">
        <Heading size="md" color="red.500">
          {error}
        </Heading>
      </Flex>
    );
  }

  const handleRequestNewDataset = () => {
    toast({
      title: 'Access Denied',
      description: 'You are not authorized to request a new dataset.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      icon: <FiAlertTriangle />,
    });
  };

  return (
    <Box p="6" bg="#F7F9FC">
      {/* Insights Section */}
      <Box mb="8">
        <Heading size="md" mb="6" color="#0765FF">
          Insights
        </Heading>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing="8">
          <StatBox title="Total Users" value={insights.totalUsers} icon={FiUser} color="#0765FF" />
          <StatBox title="Total Datasets" value={insights.totalDatasets} icon={FiDatabase} color="#0765FF" />
          <StatBox title="Total Files" value={insights.totalFiles} icon={FiFile} color="#0765FF" />
          <StatBox title="Total Downloads" value={insights.totalDownloads} icon={FiDownload} color="#0765FF" />
        </SimpleGrid>
      </Box>

      {/* Data Table and Storage Usage Section */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="8">
        {/* Data Table Section */}
        <Box
          p="6"
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          border="1px solid #E2E8F0"
        >
          <Flex justify="space-between" align="center" mb="6">
            <Heading size="md" color="#0765FF">Data Sources</Heading>
            <Flex gap="4">
              <Button
                leftIcon={<FiPlus />}
                colorScheme="blue"
                variant="outline"
                size="sm"
                onClick={handleRequestNewDataset}
              >
                Request New Dataset
              </Button>
              <Button
                leftIcon={<FiExternalLink />}
                colorScheme="blue"
                variant="solid"
                size="sm"
                onClick={() => navigate('/datasets')}
              >
                Go to Datasets
              </Button>
            </Flex>
          </Flex>

          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr bg="#F2F7FB">
                  <Th color="#0765FF" fontSize="sm">Sl. No</Th>
                  <Th color="#0765FF" fontSize="sm">Datasource Name</Th>
                  <Th color="#0765FF" fontSize="sm">Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {datasets.map((dataset, index) => (
                  <Tr key={dataset._id} _hover={{ bg: '#F9FAFB' }}>
                    <Td>{index + 1}</Td>
                    <Td>
                      <Flex align="center">
                        <Icon as={FiDatabase} mr="2" color="#0765FF" />
                        {dataset.name}
                      </Flex>
                    </Td>
                    <Td>
                      <Box as="span" color={getStatusColor(dataset.status)} fontWeight="bold">
                        {dataset.status}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        {/* Storage Usage Section */}
        <Box
          p="6"
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          border="1px solid #E2E8F0"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress value={(totalStorageUsed / 100) * 100} color="#0765FF" size="120px">
            <CircularProgressLabel fontWeight="bold">{Math.round(totalStorageUsed)}%</CircularProgressLabel>
          </CircularProgress>
          <Text mt="4" fontWeight="medium" color="gray.600">Storage Used ({Math.round(totalStorageUsed)} GB of 100 GB)</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

// Reusable component for each stat box
const StatBox = ({ title, value, icon, color }) => (
  <Box
    p="6"
    bg="white"
    borderRadius="lg"
    boxShadow="md"
    border="1px solid #E2E8F0"
    textAlign="center"
  >
    <Icon as={icon} boxSize="7" color={color} mb="3" />
    <Stat>
      <StatLabel fontWeight="medium" color="gray.600" fontSize="md">{title}</StatLabel>
      <StatNumber fontSize="3xl" fontWeight="bold">
        {value}
      </StatNumber>
    </Stat>
  </Box>
);

// Function to get color based on status
const getStatusColor = (status) => {
  switch (status) {
    case 'Active':
      return 'green.500';
    case 'Inactive':
      return 'red.500';
    case 'Pending':
      return 'orange.500';
    default:
      return 'gray.500';
  }
};

export default Dashboard;
