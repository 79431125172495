import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box
      as="footer"
      width="100%"
      height="50px"
      bg="white"
      color="gray.600"
      padding="10px 20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderTop="1px solid"
      borderColor="gray.200" // Consistent flat line for the footer
    >
      <Text fontSize="sm">© {new Date().getFullYear()} Datasphere | Ikure Data Portal | Ikure Techsoft Pvt Ltd. All rights reserved.</Text>
    </Box>
  );
};

export default Footer;
