import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.yourdomain.com', // Default API URL
  timeout: 10000, // Request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor: Attach tokens or other headers before requests
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add authorization headers or modify config here
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle error responses
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        // Handle unauthorized access (e.g., redirect to login)
        console.error('Unauthorized, redirecting...');
      } else if (status === 500) {
        // Handle server errors
        console.error('Server error, try again later.');
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
