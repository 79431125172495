import React from 'react';
import { Box, Flex, VStack, Tooltip, Icon, IconButton, useToast } from '@chakra-ui/react';
import { FiHome, FiSettings, FiDatabase, FiFileText, FiLogOut } from 'react-icons/fi';
import { GiBrain } from "react-icons/gi";
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/logo/data-square-white.svg';
import { useAuth } from 'react-oidc-context';

const Sidebar = () => {
  const auth = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  // Function to handle restricted pages
  const handleRestrictedAccess = (page) => {
    toast({
      title: "Access Denied",
      description: `Sorry, you do not have access to the ${page} page.`,
      status: "warning",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Box
      as="nav"
      pos="fixed"
      left="0"
      top="0"
      height="100vh"
      width="70px"
      bg="#0765FF" // Sidebar background color
      boxShadow="lg"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingTop="10px"
      paddingBottom="10px"
      zIndex="1000"
      justifyContent="space-between" // Space between top items and logout
    >
      {/* Top Section */}
      <Box>
        {/* Logo */}
        <Box mb="20px">
          <img src={logo} alt="Datasphere Logo" style={{ width: '40px', height: '40px' }} />
        </Box>

        {/* Navigation Items */}
        <VStack spacing="6" mt="10px">
          <NavItem icon={FiHome} label="Home" to="/home" />
          <NavItem icon={FiDatabase} label="Datasets" to="/datasets" />
          {/* Restricted Navigation Items */}
          <NavItem
            icon={GiBrain }
            label="AI"
            to="/files"
            restricted
            onRestrictedAccess={() => handleRestrictedAccess('AI')}
          />
          <NavItem
            icon={FiSettings}
            label="Settings"
            to="/settings"
            restricted
            onRestrictedAccess={() => handleRestrictedAccess('Settings')}
          />
        </VStack>
      </Box>

      {/* Logout Icon */}
      <Tooltip label="Logout" placement="right" hasArrow>
        <IconButton
          icon={<FiLogOut />}
          onClick={() => auth.signoutRedirect()}
          variant="ghost"
          aria-label="Logout"
          fontSize="20px"
          color="white"
          _hover={{ bg: 'rgba(255, 255, 255, 0.2)' }}
        />
      </Tooltip>
    </Box>
  );
};

// Sidebar navigation item component with restricted access handling
const NavItem = ({ icon, label, to, restricted = false, onRestrictedAccess }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (restricted) {
      e.preventDefault(); // Prevent navigation
      onRestrictedAccess(); // Trigger the toaster warning
    } else {
      navigate(to); // Normal navigation if not restricted
    }
  };

  return (
    <Tooltip label={label} placement="right" hasArrow>
      <NavLink to={to} style={{ width: '100%' }} onClick={handleClick}>
        {({ isActive }) => (
          <Flex
            align="center"
            justify="center"
            width="100%"
            padding="12px 0"
            position="relative"
            cursor="pointer"
          >
            <Flex
              align="center"
              justify="center"
              borderRadius="12px" // Rounded square effect
              padding="8px"
              border={isActive ? '1px solid white' : 'none'} // Thinner border for active state
              boxShadow={isActive ? '0px 0px 6px rgba(255, 255, 255, 0.2)' : 'none'} // Subtle shadow
              transition="all 0.3s ease" // Smooth transition
            >
              <Icon
                as={icon}
                boxSize="20px" // Icon size
                strokeWidth="1.5px" // Thinner line for icons
                color="white" // Icon color remains white
              />
            </Flex>
          </Flex>
        )}
      </NavLink>
    </Tooltip>
  );
};

export default Sidebar;
