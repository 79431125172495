import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Box,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useToast,
  Flex,
  Image,
  Avatar,
} from '@chakra-ui/react';
import { FiSend } from 'react-icons/fi';

function DrawerTitle({ title, subtitle, src }) {
  return (
    <>
      <Flex alignItems="center">
        <Image
          src={src}
          alt="Logo"
          boxSize="36px" // Smaller, polished logo size
          mr={3} // Proper spacing between logo and text
        />
        <Text fontSize={{ base: "18px", md: "20px" }} fontWeight="bold" noOfLines={1}>
          {title}
        </Text>
      </Flex>
      <Box mt={2} px={4} py={2} borderRadius="md">
        <Text fontWeight="normal" fontSize={{ base: "12px", md: "14px" }} color="gray.600">
          {subtitle}
        </Text>
      </Box>
    </>
  );
}

const ChatDrawer = ({ isOpen, onClose }) => {
  const [chatMessages, setChatMessages] = useState([
    {
      text: "Hello! I'm your AI assistant. How can I help you with your data today?",
      sender: 'ai',
    },
  ]);
  const [newMessage, setNewMessage] = useState('');
  const toast = useToast();

  const handleSendMessage = () => {
    toast({
      title: 'Service Not Activated',
      description: 'Chat with data is not available for your account.',
      status: 'warning',
      duration: 4000,
      isClosable: true,
      position: 'top-right',
    });

    setNewMessage('');
  };

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader px={4} py={4} borderBottom="1px solid #ddd">
          <DrawerTitle
            title="Chat with Data"
            subtitle="Unlock the full potential of your data by asking detailed questions and receiving precise insights"
            src="https://i.postimg.cc/ryjjMxZ2/documentation.png" // Replace with your logo path
          />
        </DrawerHeader>

        <DrawerBody display="flex" flexDirection="column" justifyContent="space-between" px={4} bg="white">
          <VStack spacing="4" align="stretch" overflowY="auto" flex="1" py="4">
            {chatMessages.map((msg, index) => (
              <Flex key={index} alignItems="flex-start" mb="2">
                {msg.sender === 'ai' && (
                  <Avatar
                    name="Chatbot"
                    size="sm"
                    src="https://i.postimg.cc/SK1V8ksT/Chatbot-Chat-Message.jpg" // Replace with your avatar image URL
                    mr="2"
                  />
                )}
                <Box
                  bg={msg.sender === 'user' ? 'blue.500' : 'gray.200'}
                  color={msg.sender === 'user' ? 'white' : 'black'}
                  p="3"
                  borderRadius="lg"
                  fontSize="sm"
                  maxWidth="75%"
                >
                  {msg.text}
                </Box>
              </Flex>
            ))}
          </VStack>

          <Box py="4">
            <InputGroup size="md">
              <Input
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                borderRadius="full"
                bg="white"
                _focus={{ borderColor: 'blue.500' }}
              />
              <InputRightElement>
                <Button onClick={handleSendMessage} colorScheme="blue" size="sm" borderRadius="full">
                  <FiSend />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ChatDrawer;
