import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Badge,
  Spinner,
  Text,
  IconButton,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { FiDownload, FiArrowLeft, FiMessageSquare, FiSettings, FiGrid } from 'react-icons/fi';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ChatDrawer from '../components/ChatDrawer';

const FilePreview = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChatDrawerOpen, setChatDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchCSVData = async () => {
      try {
        const response = await fetch(state.fileUrl);
        const text = await response.text();

        Papa.parse(text, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            const filteredData = result.data.filter((row) =>
              Object.values(row).some((value) => value !== null && value !== '')
            );

            setRowData(filteredData);
            if (result.meta.fields) {
              setColumnDefs([
                {
                  headerName: '#',
                  field: 'rowIndex',
                  valueGetter: 'node.rowIndex + 1',
                  pinned: 'left',
                  width: 60,
                  cellStyle: { backgroundColor: '#e6f7ff', color: '#0765FF', fontWeight: 'bold' },
                  lockPosition: true,
                  suppressMovable: true,
                },
                ...result.meta.fields.map((field) => ({
                  headerName: field,
                  field,
                  filter: 'agSetColumnFilter', // Enable Set Filter for this column
                  filterParams: {
                    // Automatically populate filter options based on row data
                    values: (params) => {
                      params.success(params.data.map((row) => row[field]));
                    },
                  },
                  sortable: true,
                  resizable: true,
                })),
              ]);
            }
            setLoading(false);
          },
          error: () => {
            setError('Failed to parse the CSV file.');
            setLoading(false);
          },
        });
      } catch {
        setError('Failed to load the CSV file.');
        setLoading(false);
      }
    };

    fetchCSVData();
  }, [state.fileUrl]);

  const handleDownloadFile = () => {
    const link = document.createElement('a');
    link.href = state.fileUrl;
    link.download = state.fileName;
    link.click();
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box p="6" position="relative" height="100vh" overflow="hidden">
      {/* Top Controls */}
      <Flex justifyContent="space-between" alignItems="center" mb="4">
        <Button leftIcon={<FiArrowLeft />} onClick={() => navigate(-1)}>
          Back
        </Button>
        <Text fontWeight="bold" fontSize="lg">
          {state.datasetName} / {state.fileName}
        </Text>
        <Button colorScheme="blue" leftIcon={<FiDownload />} onClick={handleDownloadFile}>
          Download File
        </Button>
      </Flex>

      {/* File Information */}
      <Flex mb="4" gap="4">
        <Badge colorScheme="blue" fontSize="sm" borderRadius="md" px="3" py="1">
          Rows: {rowData.length}
        </Badge>
        <Badge colorScheme="blue" fontSize="sm" borderRadius="md" px="3" py="1">
          Columns: {columnDefs.length}
        </Badge>
      </Flex>

      {/* AG Grid for Data Preview */}
      <Box className="ag-theme-alpine" style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination
          paginationPageSize={20}
          rowSelection="multiple"
          suppressRowClickSelection
          animateRows
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
        />
      </Box>

      {/* Floating Toolbar */}
      <VStack
        position="fixed"
        right="10px"
        top="50%"
        transform="translateY(-50%)"
        bg="#e0e8ff"
        color="#0765FF"
        boxShadow="lg"
        borderRadius="md"
        spacing="2"
        padding="2"
        zIndex="1000"
        border="1px solid #E2E8F0"
      >
        <Tooltip label="Chat with Data" placement="left" hasArrow fontSize="xs">
          <IconButton
            icon={<FiMessageSquare />}
            aria-label="Chat with Data"
            variant="ghost"
            size="sm"
            color="#0765FF"
            onClick={() => setChatDrawerOpen(true)}
          />
        </Tooltip>
        <Tooltip label="Query Builder" placement="left" hasArrow fontSize="xs">
          <IconButton
            icon={<FiSettings />}
            aria-label="Query Builder"
            variant="ghost"
            size="sm"
            color="#A0AEC0"
            isDisabled
          />
        </Tooltip>
        <Tooltip label="Dashboard" placement="left" hasArrow fontSize="xs">
          <IconButton
            icon={<FiGrid />}
            aria-label="Dashboard"
            variant="ghost"
            size="sm"
            color="#A0AEC0"
            isDisabled
          />
        </Tooltip>
      </VStack>

      {/* Chat Drawer */}
      <ChatDrawer isOpen={isChatDrawerOpen} onClose={() => setChatDrawerOpen(false)} />
    </Box>
  );
};

export default FilePreview;
