import React from 'react';
import ReactDOM from 'react-dom/client'; // Corrected import
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'react-oidc-context';
import App from './App';
import './index.css';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
};

const root = ReactDOM.createRoot(document.getElementById('root')); // Updated to use createRoot from react-dom/client

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>
);
