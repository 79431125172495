import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} height="100vh" overflow="hidden">
      <Sidebar />
      <Flex
        direction="column"
        flex="1"
        ml={{ base: '0', md: '80px' }} // Sidebar width adjustment
        mt="80px" // Adjusted for increased topbar height
        bg="white"
      >
        <Topbar />
        <Box as="main" flex="1" p="6" overflowY="auto">
          {children}
        </Box>
        <Footer />
      </Flex>
    </Flex>
  );
};

export default Layout;
